


































































































































































import {Component, Vue} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import {namespace} from 'vuex-class';
import moment from 'moment/moment';
import SideCardComponent from '@/components/shared/SideCard/SideCard.component.vue';
import Address from '@/models/Address';
import {Permission} from '@/misc/enums/permission.enum';

const CustomerStore = namespace('customer');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    SideCardComponent,
    CustomerManageComponent: () => import(
      /* webpackChunkName: "CustomerManageComponent" */
      '@/components/customer/CustomerManage.component.vue'),
  },
})
export default class MasterDataComponent extends Vue {
  @CustomerStore.Action('editCustomerAction')
  public editCustomerAction!: (customer: Customer) => Promise<Customer>;
  @CustomerStore.Action('deleteCustomerAction')
  public deleteCustomerAction!: (customer: Customer) => Promise<Customer>;
  @CustomerStore.Getter('customer')
  public customer!: Customer;

  public showEditModal: boolean = false;
  public showDeleteDialog: boolean = false;
  @CustomerStore.Mutation('storeCustomer')
  private storeCustomer!: (customer?: Customer) => void;

  private showChangeStatusDialog: boolean = false;

  /**
   * formats the created at date to the format, specified in the locales.
   */
  public get getDate() {
    const createdAt = new Date(this.customer!.createdAt);
    return moment(createdAt.toISOString()).format(`${this.$t('GENERAL.DATE_FORMATS.DATE')}`);
  }

  private onShowChangeStatusDialog() {
    if (this.$userRoleHandler.hasPermission(Permission.CUSTOMER_UPDATE_OWN)) {
      this.showChangeStatusDialog = true;
    }
  }

  /**
   * Open the edit modal view
   */
  public onEditMasterData() {
    this.showEditModal = true;
  }

  /**
   * checks if the customer has a proper invoice address
   */
  public get invoiceAddress(): Address {
    return this.customer.invoiceAddress || this.customer.address!;
  }

  public async closeManageComponent(updatedItem: Customer) {
    if (updatedItem) {
      this.storeCustomer(updatedItem);
    }
    this.showEditModal = false;
  }

  public async onChangeStatus() {
    this.showChangeStatusDialog = false;
    try {
      const updatedItem = this.customer;
      updatedItem!.active = !updatedItem!.active;
      // edit the specified item and store it to reassign after editing
      this.storeCustomer(await this.editCustomerAction(updatedItem as Customer));
      if (updatedItem!.active) {
        this.$notifySuccessSimplified('CUSTOMER.NOTIFICATIONS.ACTIVATE');
      } else {
        this.$notifySuccessSimplified('CUSTOMER.NOTIFICATIONS.DEACTIVATE');
      }
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  public async onDeleteItem() {
    this.showDeleteDialog = false;
    try {
      await this.deleteCustomerAction(this.customer);
      await this.$router.push({name: 'customersOverview', params: {companyId: this.$route.params.companyId}});
      this.$notifySuccessSimplified('CUSTOMER.NOTIFICATIONS.DELETE.SUCCESS');
    } catch (e: any) {
      this.$notifyErrorSimplified('CUSTOMER.NOTIFICATIONS.DELETE.ERROR');
    }
  }

  private get isActive() {
    return {
      opposite: this.customer.active ? this.$t('GENERAL.INACTIVE').toString().toLowerCase() :
        this.$t('GENERAL.ACTIVE').toString().toLowerCase(),
    };
  }
}
